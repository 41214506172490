<template>
  <div>
    <route-buttons />
    <b-row class="match-height">
      <b-col md="6">
        <b-row class="match-height">
          <b-col md="6">
            <b-card :title="canva[0].title">
              <text-items :text-data="canva[0].text" />
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card :title="canva[1].title">
              <text-items :text-data="canva[1].text" />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-card :title="canva[2].title">
          <text-items :text-data="canva[2].text" />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="6">
        <b-card :title="canva[3].title">
          <text-items :text-data="canva[3].text" />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-row class="match-height">
          <b-col md="6">
            <b-card :title="canva[4].title">
              <text-items :text-data="canva[4].text" />
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card :title="canva[5].title">
              <text-items :text-data="canva[5].text" />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="4">
        <b-card :title="canva[6].title">
          <text-items :text-data="canva[6].text" />
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card :title="canva[7].title">
          <text-items :text-data="canva[7].text" />
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card :title="canva[8].title">
          <text-items :text-data="canva[8].text" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import TextItems from '@/views/App/Reports/components/canva/TextItems.vue'
import routeButtons from '@/views/App/Reports/components/Route_buttons.vue'

export default {
  name: 'Canva',
  components: {
    BRow,
    BCol,
    BCard,
    TextItems,
    routeButtons,
  },
  computed: {
    canva() {
      return this.$store.getters['businessModelAnalysisReport/getCanva']
    },
  },
  created() {
    this.getCanva()
  },
  methods: {
    getCanva() {
      this.$store.dispatch('businessModelAnalysisReport/canva', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
