<template>
  <div>
    <div
      v-for="(text,key) in textData"
      :key="key"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextItems',
  props: ['textData'],
}
</script>

<style scoped>

</style>
